import { Grid, Typography, Box, Card, CardContent, CardMedia, IconButton, lighten } from '@mui/material';
import React from 'react';
import Navbar from './Navbar';
import { Icon } from "@iconify/react";

const WishlistScreen = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;

    const products = [
        {
            name: 'Apple MacBook Pro',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image: "./assets/images/Laptop1.png",
            rating: 3,
            liked: true,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop2.png",
            rating: 2,
            liked: false,
        },
        {
            name: 'Apple 2020 MacBook',
            price: '₹90,891',
            originalPrice: '₹1,00,000',
            discount: '10% off',
            image: "./assets/images/Laptop3.png",
            rating: 2,
            liked: false,
        },
        {
            name: 'MSI Titan 18HX',
            price: '₹4,09,990',
            originalPrice: '₹4,99,990',
            discount: '18% off',
            image: "./assets/images/Laptop4.png",
            rating: 3,
            liked: true,
        },
    ];

    const products2 = [
        {
            name: 'Apple MacBook Pro',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image: "./assets/images/Laptop1.png",
            rating: 3,
            liked: true,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop2.png",
            rating: 3,
            liked: false,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop3.png",
            rating: 1,
            liked: false,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop4.png",
            rating: 2,
            liked: false,
        },
    ];

    return (
        <Grid container
            direction="column"
            sx={{
                height: 'auto',
                border: '4px solid grey',
                borderRadius: '8px',
            }}
        >
            <Navbar />
            <Grid container item sx={{ justifyContent: 'center' }}>
                <Grid item sx={{ width: { xs: '95%', sm: '90%' }, borderRadius: '12px' }}>
                    <Typography sx={{ fontSize: '13px',  fontFamily:'Inter', fontWeight: '500', lineHeight: '16px', color: '#000000', mt: '10px' }}>Wishlist</Typography>
                    <Grid container spacing={'5px'} sx={{ mt: '0px' }}>
                        {products.map((product, index) => (
                            <Grid item xs={12} sm={6} md={5} lg={6} key={index} sx={{ mb: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        position: 'relative',
                                        padding: '8px',
                                        backgroundColor: '#fff',
                                        border: `1px solid ${lighten(extractedColor, 0.5)}`,
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '16px',
                                    }}
                                >
                                    {/* Product Image */}
                                    <Box
                                        component="img"
                                        src={product.image}
                                        alt={product.name}
                                        sx={{ width: '100px', height: '65px', objectFit: 'contain', marginTop: '10px' }}
                                    />

                                    {/* Product Details */}
                                    <Box sx={{ marginLeft: '3px', }}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '9px',
                                                color: '#000',
                                                 fontFamily:'Inter',
                                                mb: '4px',
                                                lineHeight: '12px'
                                            }}
                                        >
                                            {product.name}
                                        </Typography>
                                        {/* Price Section */}
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '10px',  fontFamily:'Inter', lineHeight: '12px' }}>
                                                {product.price}
                                            </Typography>

                                            <Typography sx={{ textDecoration: 'line-through', ml: '3px', mr: '3px', color: '#9e9e9e', fontSize: '9px',  fontFamily:'Inter', lineHeight: '12px', fontWeight: '500' }}>
                                                {product.originalPrice}
                                            </Typography>
                                            <Typography sx={{ color: extractedColor, fontSize: '9px',  fontFamily:'Inter', lineHeight: '12px', fontWeight: '500' }}>
                                                {product.discount}
                                            </Typography>
                                        </Box>

                                        {/* Rating */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '8px', mt: '5px' }}>
                                            {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                                                <Icon
                                                    key={i}
                                                    icon="mdi:star"
                                                    style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }}
                                                />
                                            ))}
                                            <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '7px', fontFamily: '"Inter", sans-serif' }}>7327 ratings & 5467 reviews</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '3px' }}>
                                            <Box sx={{ width: '70px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.5)}`, textAlign: 'center', alignItems: 'center', backgroundColor: extractedColor }}>
                                                <Typography sx={{ fontWeight: '500', fontSize: '9px', fontFamily: '"Inter", sans-serif' }}>Move to cart</Typography>
                                            </Box>
                                            <Box sx={{ width: '70px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.5)}`, textAlign: 'center', alignItems: 'center' }}>
                                                <Typography sx={{ fontWeight: '500', fontSize: '9px', fontFamily: '"Inter", sans-serif' }}>Remove </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px', width: '100%' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Recently Viewed</Typography>
                        <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#6B7280', fontFamily: '"Inter", sans-serif"', color: `${extractedColor}` }}> View all </Typography>
                    </Box>

                    <Grid container spacing={1} sx={{ mt: '6px' }}>
                        {products2.map((product, index) => (
                            <Grid item xs={6} sm={4} md={3} key={index}>
                                <Card sx={{ border: `1px solid ${extractedColor}`, borderRadius: '15px', padding: '12px', position: 'relative' }}>
                                    <Box sx={{ position: 'absolute', top: '4px', right: '1px' }}>
                                        <IconButton>
                                            <Icon
                                                icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                                style={{ color: `${extractedColor}`, fontSize: '15px' }}
                                            />
                                        </IconButton>
                                    </Box>
                                    <CardMedia
                                        component="img"
                                        height="90px"
                                        image={product.image}
                                        alt={product.name}
                                        sx={{ objectFit: 'contain', mt: '5px', paddingRight: '10px' }}
                                    />

                                    <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000',  fontFamily:'Inter', mt: '3px' }}>
                                        {product.name}
                                    </Typography>
                                    <Box sx={{ mt: '2px' }}>
                                        <Typography sx={{ color: '#000', fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                                            {product.price}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default WishlistScreen;
