import React from 'react';
import { Box, Button, Container, Divider, Typography, Link, Grid, TextField, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';

const EmailLoginScreen = (data) => {
  const storedAppData = data.data;
  console.log("ddd", storedAppData);
  const colorGradient = storedAppData.theme_color;


  // Use a regular expression to extract the color code
  const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
  const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found

  console.log("Extracted Color:", extractedColor);
  return (
    <Grid container
      direction="column"  
      sx={{
        height: '364px',
        background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
        border: '4px solid grey',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box item sx={{ textAlign: 'center' }}>

        <img
          src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
            ? storedAppData.logo.selectedImage
            : "../assets/images/defaultLogo.png"}

          style={{ width: '25px', height: '25px' }}
        />


        {/* App Name */}
        <Typography sx={{ fontSize: '12px', fontFamily:storedAppData?.fontFamily ? storedAppData.fontFamily : 'Inter', fontWeight: '500', mt: '0px' }}>
          {storedAppData.application_name}
        </Typography>
      </Box>

      <Grid item sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, width: '252px', height: '270px', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
        <Box

          sx={{

            padding: '8px 54px',
            borderRadius: '16px',
            textAlign: 'center',
            width: '100%',

          }}
        >
          <Box sx={{ textAlign: 'center', }}>
            <Typography sx={{ fontWeight: '500', fontSize: '14px',  fontFamily:'Inter', }}>Sign In</Typography>
            <Typography sx={{ fontSize: '6px',  fontFamily:'Inter', }}>
              Please enter your Email address & password to login to your account
            </Typography>
          </Box>

          {/* Email and Password Input */}

          {/* <ReusableFormTextField
            customStyles={{ height: "20px", borderRadius: '4px', border: `0.6px solid ${extractedColor}`, padding: '0px', fontSize: '9px', marginTop: '10px' }}
            placeholder="Email"
            disabled

          /> */}
           <Box sx={{ height: '20px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '4px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center' }}>
            <Typography sx={{ color: 'grey', fontSize: '9px', fontWeight: '500' }} >Email</Typography>
          </Box>
          {/* <ReusableFormTextField
            customStyles={{ height: "20px", borderRadius: '4px', border: `0.6px solid ${extractedColor}`, padding: '0px', fontSize: '9px', marginTop: '10px' }}
            placeholder="Password"

          /> */}
           <Box sx={{ height: '20px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '4px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', marginTop: '10px' }}>
            <Typography sx={{ color: 'grey', fontSize: '9px', fontWeight: '500' }} >Password</Typography>
          </Box>




          {/* Forgot Password Link */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', mb: 1, mt: '2px' }}>
            <Link href="#" underline="none" sx={{ color: `${extractedColor}`, fontSize: '7px', fontWeight: '500',  fontFamily:'Inter', }}>
              Forgot password?
            </Link>
          </Box>

          {/* Sign In Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{

              backgroundColor: `${extractedColor}`,
              '&:hover': { backgroundColor: `${extractedColor}` },
              textTransform: 'none',
              color: '#4B5563',
              fontWeight: '600',
              fontSize: '8px',
               fontFamily:'Inter',
              borderRadius: '4px',
              padding: '4px 7px'
            }}
          >
            Sign In
          </Button>
          {/* Divider with OR */}
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px', marginBottom: '8px' }}>
            <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#9CA3AF', mr: '10px' }} />
            <Typography sx={{ fontSize: '8px', fontWeight: '400' }}>OR</Typography>
            <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#9CA3AF', marginLeft: '10px' }} />
          </Box>

          {/* Social Login Buttons */}
          <Button
            variant="outlined"
            fullWidth
            sx={{
              mb: '4px',
              borderColor: `${lighten(extractedColor,0.5)}`,
              color: '#000000',
              fontSize: '7px',
              fontWeight: '500',
              textTransform: 'none',
              backgroundColor: '#FFFFFF',
               fontFamily:'Inter',
              '&:hover': { backgroundColor: '#FFFFFF', borderColor: `${lighten(extractedColor,0.5)}` },
              borderRadius: '4px',
              padding: '4px 5px'
            }}
          >
            <Icon style={{ marginRight: '8px' }} icon="flat-color-icons:google" width="13px" height="13px" />
            Sign In with Google
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{

              borderColor: `${lighten(extractedColor,0.5)}`,
              color: '#000000',
              fontSize: '7px',
              fontWeight: '500',
              textTransform: 'none',
              backgroundColor: '#FFFFFF',
               fontFamily:'Inter',
              '&:hover': { backgroundColor: '#FFFFFF', borderColor: `${lighten(extractedColor,0.5)}` },
              borderRadius: '4px',
              padding: '4px 5px'
            }}
          >
            <Icon style={{ marginRight: '8px' }} icon="logos:facebook" width="13px" height="13px" />
            Sign In with Facebook
          </Button>

          {/* Sign Up Link */}
          <Box sx={{ backgroundColor: '#FFFFFF', width: '100%', padding: '7px 0px', textAlign: 'center', borderRadius: '16px' }}>
            <Typography sx={{ color: 'black', fontSize: '8px', fontWeight: '400', fontFamily:'Inter', }}>
              Don’t have an account? <span style={{ marginLeft: '3px', color: `${extractedColor}`,fontWeight:'500', fontFamily:'Inter', }}>Sign Up</span>
            </Typography>
          </Box>
        </Box>
      </Grid>

    </Grid>
  )
}

export default EmailLoginScreen
