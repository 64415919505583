import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, Divider, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const ShoppingCart = (data) => {
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;

    // Extract color from the gradient string
    const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
    const extractedColor = colorMatch ? colorMatch[0] : '#000'; // fallback color

    const products = [
        {
            id: 1,
            name: "Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma).....",
            image:  "./assets/images/Laptop1.png",
            price: "₹3,39,915",
            originalPrice: "₹3,99,990",
            discount: "15% off",
            rating: 4.5,
            liked: true 
        },
    ];
    const products2 = [
        {
            name: 'Apple MacBook Pro',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image:  "./assets/images/Laptop1.png",
            rating: 3,
            liked: true,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image:  "./assets/images/Laptop2.png",
            rating: 4,
            liked: false,
        },
    ];
    return (
        <Grid container
            sx={{
                height: '780px',
                border: '10px solid grey',
                borderRadius: '20px',
                width: '300px',
                position:'relative'

            }}
        >
            <TopNotch/>
            <Grid container item sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: '22px',
                padding: '15px 6px 0px 6px',
                boxShadow: `0px 4px 4px -2px ${lighten(extractedColor,0.7)}`,
                height: '48px',

            }}>
                <Icon icon="line-md:arrow-left" width="24px" height="24px" style={{ color: '#000000' }} />
                <Typography sx={{  fontFamily:'Inter', fontSize: '18px', ml: '5px',fontWeight:'500', }}>Shopping Cart</Typography>
                <Icon icon="mdi:heart-outline" width="24px" height="24px"  style={{color:'#000000'}} />
            </Grid>
            <Box sx={{ padding: '5px' }}>
                <Grid container sx={{ mt: '15px', padding: '5px', border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '16px', }}>
                    {products.map((product, index) => (
                        <Grid item xs={12} key={index} sx={{}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    padding: '5px',
                                    backgroundColor: '#fff',
                                }}
                            >
                                {/* Product Image */}
                                <Box
                                    component="img"
                                    src={product.image}
                                    alt={product.name}
                                    sx={{ width: '90px', height: '60px', }}
                                />


                                {/* Product Details */}
                                <Box sx={{ marginLeft: '16px', flexGrow: 1 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '10px',
                                            color: '#000',
                                             fontFamily:'Inter',
                                            mb: '4px',
                                        }}
                                    >
                                        {product.name}
                                    </Typography>
                                    {/* Price Section */}
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: '500', color: '#000', fontSize: '10px',  fontFamily:'Inter', }}>
                                            {product.price}
                                        </Typography>

                                        <Typography sx={{ textDecoration: 'line-through', ml: '3px', mr: '3px', color: '#9e9e9e', fontSize: '10px',  fontFamily:'Inter', }}>
                                            {product.originalPrice}
                                        </Typography>
                                        <Typography sx={{ color: extractedColor, fontSize: '10px',  fontFamily:'Inter', }}>
                                            {product.discount}
                                        </Typography>

                                    </Box>

                                </Box>
                            </Box>
                        </Grid>
                    ))}
                    <Box sx={{ display: 'flex', mb: '10px', ml: '5px', mt: '3px' }}>
                        <Box sx={{ display: 'flex', width: '80px', height: '24px', border: `1px solid ${lighten(extractedColor,0.5)}`, mr: '20px', borderRadius: '4px', textAlign: 'center' }}>
                            <Box sx={{ borderRight: `1px solid ${lighten(extractedColor,0.5)}`, width: '20px' }}>
                                <Icon icon="ic:baseline-minus" width="12px" height="12px" style={{ color: '#000000' }} />
                            </Box>
                            <Box sx={{ borderRight: `1px solid ${lighten(extractedColor,0.5)}`, width: '40px' }}>
                                1
                            </Box>
                            <Box sx={{ width: '20px' }}>
                                <Icon icon="ic:baseline-plus" width="12px" height="12px" style={{ color: '#000000' }} />
                            </Box>
                          </Box>
                        <Box sx={{ width: '112px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor,0.5)}`, textAlign: 'center', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: '500', fontSize: '10px',  fontFamily:'Inter', }}>Move to wishlist</Typography>
                        </Box>
                        <Box sx={{ borderRadius: '4px', border: `1px solid ${lighten(extractedColor,0.5)}`, ml: '10px', padding: '0px 5px' }}>
                            <Icon icon="material-symbols:delete" width="12px" height="12px" style={{ color: '#000000' }} />

                        </Box>
                    </Box>
                </Grid>

                <Box
                    sx={{
                        border: `1px solid ${lighten(extractedColor,0.5)}`,
                        borderRadius: '8px',
                        padding: '8px 10px',
                        mt: '10px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '14px', lineHeight: '14px', paddingBottom: '10px',  fontFamily:'Inter', borderBottom: `1px solid ${lighten(extractedColor,0.5)}`, }}>
                        Price summary
                    </Typography>

                    {/* Coupon Input and Button */}
                    <Box sx={{ display: 'flex', gap: '10px', mb: 3, mt: '8px' }}>
                        <TextField
                            fullWidth
                            placeholder="Apply gift card or coupon code"
                            variant="outlined"
                            size="small"

                            InputProps={{
                                sx: {
                                    fontSize: '10px',  
                                    padding: '0px 0px', 
                                    borderRadius: '8px',
                                    color: '#000000'
                                },
                            }}
                        />

                        <Button
                            variant="contained"
                           sx={{
                                backgroundColor: lighten(extractedColor,0.5),
                                padding: '0px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                color: '#000000',
                                fontSize:'12px',
                                ':hover': { backgroundColor: lighten(extractedColor,0.5), color: '#000000' }
                            }}
                        >
                            Apply
                        </Button>
                    </Box>

                    {/* Price Details */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>Sub total</Typography>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>₹3,39,915</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>Taxes</Typography>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>+₹9,999</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, }}>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>Discounts</Typography>
                        <Typography sx={{ fontWeight: '400', color: extractedColor, fontSize: '12px',  fontFamily:'Inter', }}>-₹9,999</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>Shipping</Typography>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>+₹999</Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderTop: `1px solid ${lighten(extractedColor,0.5)}`,
                            paddingTop: '10px',

                        }}
                    >
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>Total</Typography>
                        <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '12px',  fontFamily:'Inter', }}>₹3,40,914</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px',padding:'0px 3px' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Recently Viewed</Typography>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#6B7280',  fontFamily:'Inter', color: `${extractedColor}` }}>View all</Typography>
                </Box>
                <Grid container spacing={1} sx={{ mt: '6px' }}>
                    {products2.map((product, index) => (
                        <Grid item xs={6} key={index}>
                            <Card sx={{ border: ` 1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '15px', padding: '12px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: '4px', right: '0px' }}>
                                    <IconButton>
                                        <Icon
                                            icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                            style={{ color: `${extractedColor}`, fontSize: '20px' }}
                                        />
                                    </IconButton>
                                </Box>
                                <CardMedia
                                    component="img"
                                    height="90px"
                                    image={product.image}
                                    alt={product.name}
                                    sx={{ objectFit: 'contain', mt: '5px' }}
                                />

                                <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                                    {product.name}
                                </Typography>
                                <Box sx={{ mt: '3px' }}>

                                    <Typography variant="body1" sx={{ color: '#000' }}>
                                        {product.price}
                                    </Typography>

                                </Box>
                             </Card>
                        </Grid>
                    ))}
                </Grid>
             </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    boxShadow: `0px -4px 8px -2px ${lighten(extractedColor, 0.7)}`,
                    width: '100%',
                    mb:'18px',
                    mt:'5px'
                }}
            >
                <Box sx={{mt:'10px'}}>
                    <Typography sx={{ fontWeight: '500', fontSize: '9px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Total (1 item)</Typography>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>₹3,40,914</Typography>
                </Box>
                 
                <ReusableButton
                    sx={{
                        '&:hover': { backgroundColor: `${lighten(extractedColor,0.5)}` },
                        textTransform: 'none',
                        backgroundColor: `${lighten(extractedColor,0.5)}`,
                        height: '36px',
                        padding: '8px 22px',
                        borderRadius: '8px',
                        mt: '7px',
                        fontWeight: '500',
                         fontFamily:'Inter',
                        fontSize:'12px'
                    }}
                >Proceed to payment</ReusableButton>
                </Box>
                <BottomNotch/>



        </Grid>
    )
}

export default ShoppingCart
