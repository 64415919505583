import React from 'react';
import { Box, Button, Container, Divider, Typography, Link, Grid, TextField, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';


const SignupPage = (data) => {
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;
    return (
        <Grid container

            sx={{
                height: '640px',
                display: 'flex',
                alignItems: 'center',
                background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
                border: '10px solid grey',
                borderRadius: '20px',
                width: '300px',
                padding: '7px',
                position: 'relative'

            }}
        >
           <TopNotch/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '20px',
                    width: '100%', 
                }}
            >
                {/* App Logo */}
                <Box >
                    <img
                        src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
                            ? storedAppData.logo.selectedImage
                            : "../assets/images/defaultLogo.png"}

                        style={{ width: '64px', height: '64px' }}
                    />
                </Box>

                {/* App Name */}
                <Typography variant="h5" sx={{ mb: 4,  fontFamily:storedAppData?.fontFamily ? storedAppData.fontFamily : 'Inter' }}>
                    {storedAppData.application_name}
                </Typography>

                <Grid
                    container
                    spacing={2}
                    sx={{
                        border: `1px solid ${lighten(extractedColor, 0.5)}`,
                        padding: '10px',
                        borderRadius: '16px',
                        textAlign: 'center',
                        width: '100%',
                        ml: '1px'
                    }}
                >
                    <Box sx={{ textAlign: 'center', mb: '5px' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '18px',  fontFamily:'Inter', }}>Sign Up</Typography>
                        <Typography sx={{ fontSize: '13px',  fontFamily:'Inter', }}>
                            Please fill the below details to finish creating your account
                        </Typography>
                    </Box>

                    {/* Email and Password Input */}

                    <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center' }}>
                        <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500', fontFamily:'Inter', }} >Full name</Typography>
                    </Box>
                    <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', mt: '5px' }}>
                        <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500', fontFamily:'Inter', }} >Email</Typography>
                    </Box>
                    <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', mt: '5px' }}>
                        <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500', fontFamily:'Inter', }} >Password</Typography>
                    </Box>
                    <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', mt: '5px' }}>
                        <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500', fontFamily:'Inter', }} >Re-enter new password</Typography>
                    </Box>

                    {/* Sign In Button */}
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{

                            backgroundColor: `${lighten(extractedColor, 0.5)}`,
                            '&:hover': { backgroundColor: `${lighten(extractedColor, 0.5)}` },
                            textTransform: 'none',
                            color: '#4B5563',
                            fontWeight: '600',
                            fontSize: '12px',
                             fontFamily:'Inter',
                             borderRadius: '8px',
                            mt: '8px'
                        }}
                    >
                        Sign Up
                    </Button>
                </Grid>

                {/* Divider with OR */}
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                    <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#9CA3AF', marginX: '10px' }} />
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: '400' }}>OR</Typography>
                    <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#9CA3AF', marginX: '10px' }} />
                </Box>

                {/* Social Login Buttons */}
                <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                        mb: 2,
                        borderColor: `${lighten(extractedColor, 0.5)}`,
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: '500',
                        textTransform: 'none',
                        backgroundColor: '#FFFFFF',
                         fontFamily:'Inter',
                        '&:hover': { backgroundColor: '#FFFFFF', borderColor: `${lighten(extractedColor, 0.5)}` },
                        borderRadius: '8px'
                    }}
                >
                    <Icon style={{ marginRight: '8px' }} icon="flat-color-icons:google" width="15px" height="15px" />
                    Sign Up with Google
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                        mb: 2,
                        borderColor: `${lighten(extractedColor, 0.5)}`,
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: '500',
                        textTransform: 'none',
                        backgroundColor: '#FFFFFF',
                         fontFamily:'Inter',
                        '&:hover': { backgroundColor: '#FFFFFF', borderColor: `${extractedColor}` },
                        borderRadius: '8px'
                    }}
                >
                    <Icon style={{ marginRight: '8px' }} icon="logos:facebook" width="15px" height="15px" />
                    Sign Up with Facebook
                </Button>

                {/* Sign Up Link */}
                <Box sx={{ backgroundColor: '#FFFFFF', width: '100%', padding: '8px', textAlign: 'center', borderRadius: '16px', mb: '4px' }}>
                    <Typography sx={{ color: 'black', fontSize: '12px', fontWeight: '400', fontFamily:'Inter', }}>
                        Don’t have an account? <span style={{ marginLeft: '3px', color: `${extractedColor}`, fontFamily:'Inter', }}>Sign In</span>
                    </Typography>
                </Box>
            </Box>
           <BottomNotch/>
        </Grid>
    )
}

export default SignupPage
