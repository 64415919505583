import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';



const InnerProduct = (data) => {
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;

    // Extract color from the gradient string
    const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
    const extractedColor = colorMatch ? colorMatch[0] : '#000'; // fallback color
    return (
        <Grid container
            sx={{
                height: '580px',
                border: '10px solid grey',
                borderRadius: '20px',
                width: '300px',
                position:'relative'
            }}
        >
            <TopNotch/>
            <Grid container item sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 6px',
                boxShadow: `0px 4px 4px -2px ${lighten(extractedColor,0.7)}`,
                opacity: 0.6,
                border: 'none',
                height: '55px',
                mt:'22px'

            }}>
                <Icon icon="line-md:arrow-left" width="24px" height="24px" style={{ color: '#000000' }} />

                  {/* Search bar */}
                  <Box
                    sx={{
                        height: '30px',
                        border: `0.6px solid ${lighten(extractedColor, 0.8)}`,
                        padding: '0px 5px',
                        width: '200px',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: lighten(extractedColor, 0.9),

                    }}
                >
                    <Icon icon="mdi:magnify" width="20px" height="20px" style={{ color: 'grey', marginRight: '5px' }} />
                    <Typography
                        sx={{
                            color: 'grey',
                            fontSize: '12px',
                            fontWeight: '400',
                            flex: 1,
                        }}
                    >
                        Laptop
                    </Typography>
                </Box>
                <Icon icon="mdi:cart" width="24px" height="24px" style={{ color: '#000000' }} />
            </Grid>

            <Grid container item spacing={2} sx={{ padding: '10px', ml: '2px',mt:'3px' }}>

                <Grid item xs={12} sx={{ position: 'relative', border: `1px solid ${lighten(extractedColor,0.8)}`, borderRadius: '16px', }}>
                    <Box
                        component="img"
                        src="./assets/images/Laptop1.png"
                        alt="Image 1"
                        sx={{ width: '80%', height: 'auto', borderRadius: '8px', mb: '15px' }}
                    />

                    <Box sx={{ position: 'absolute', top: '8px', right: '8px' }}>
                        <IconButton>
                            <Icon
                                icon="mdi:heart"
                                style={{ color: `${extractedColor}`, fontSize: '24px' }} // Adjust color as needed
                            />
                        </IconButton>
                    </Box>

                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', ml: '70px', mt: '5px' }}>

                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: extractedColor, }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400',  fontFamily:'Inter', mt: '7px', color: '#000000' }}>Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A(16 Inch, Space Black, 2.16 kg)</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600',  fontFamily:'Inter', color: '#4B5563', mt: '3px' }}>Apple </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }} />
                    <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }} />
                    <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }} />
                    <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }} />
                    <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }} />
                    <Typography sx={{ fontSize: '8px', fontWeight: '400',  fontFamily:'Inter',mt:'1px' }}>7327 ratings & 5467 reviews</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '12px', fontFamily:'Inter', }}>
                        ₹500000
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Typography sx={{ textDecoration: 'line-through', ml: '4px', color: '#9e9e9e', fontSize: '12px', fontFamily:'Inter',fontWeight: '500', }}>
                            ₹550000
                        </Typography>
                        <Typography sx={{ color: extractedColor, fontSize: '12px', ml: '4px', fontFamily:'Inter',fontWeight: '500', }}>
                            15% off
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'start', }}>
                    <ul style={{ paddingLeft: '0px', marginLeft: '0px', listStylePosition: 'inside', marginTop: '0px', fontSize: '10px', fontWeight: '400',  fontFamily:'Inter', }}>
                        <li>Apple M3 Max Processor</li>
                        <li>16GB RAM</li>
                        <li>1TB SSD Storage</li>
                        <li>16-inch Liquid Retina Display</li>
                        <li>macOS Sonoma</li>
                    </ul>
                </Box>



            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    boxShadow: `0px -4px 8px -2px ${lighten(extractedColor, 0.7)}`,
                    width: '100%',
                }}
            >


                <ReusableButton
                    sx={{
                        '&:hover': { backgroundColor: `${lighten(extractedColor,0.5)}` },
                        textTransform: 'none',
                        backgroundColor: `${lighten(extractedColor,0.5)}`,
                        height: '36px',
                        padding: '8px 22px',
                        borderRadius: '8px',
                        mt: '7px',
                        fontWeight: '500',
                         fontFamily:'Inter',
                        fontSize:'12px'


                    }}
                >Shop now</ReusableButton>



                <Button
                    variant="outlined"
                    sx={{
                        '&:hover': {
                            backgroundColor: `${lighten(extractedColor,0.5)}`, // Add hover color if needed
                            border: `1px solid ${lighten(extractedColor,0.5)}`, // Optional: Change border on hover
                        },
                        border: `1px solid ${lighten(extractedColor,0.5)}`, // Outlined border
                        color: '#000000', // Text color to match outline
                        textTransform: 'none',
                        height: '36px',
                        padding: '8px 22px',
                        borderRadius: '8px',
                        mt: '7px',
                        fontWeight: '500',
                         fontFamily:'Inter',
                        fontSize:'12px'
                    }}
                >
                    Add to cart
                </Button>
             </Box>
             <BottomNotch/>

        </Grid>

    )
}

export default InnerProduct
