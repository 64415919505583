import { Box, Button, Grid, lighten, Slider, Typography, IconButton, Divider } from '@mui/material'
import React from 'react'
import Navbar from './Navbar';
import { Icon } from "@iconify/react";


const ProductListScreen = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;


    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;
    const products = [
        {
            id: 1,
            name: "Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A  (16 Inch, Space Black, 2.16 kg))",
            image: './assets/images/Laptop1.png',
            price: "₹3,39,915",
            originalPrice: "₹3,99,990",
            discount: "15% off",
            rating: 4.5,
            liked: true 
        },
        {
            id: 2,
            name: "MSI Titan 18HX Intel Core i9 14th Gen 14900HX - (64 GB/2 TB SSD/Windows 11 Home/12 GB Graphics/NVIDIA GeForce RTX 4080)",
            image:'./assets/images/Laptop2.png',
            price: "₹4,09,990",
            originalPrice: "₹4,59,990",
            discount: "18% off",
            rating: 5,
            liked: false 
        },
        {
            id: 3,
            name: "Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A  (16 Inch, Space Black, 2.16 kg)",
            image: './assets/images/Laptop3.png',
            price: "₹1,49,990",
            originalPrice: "₹1,89,990",
            discount: "21% off",
            rating: 4,
            liked: false
        },
        // {
        //     id: 4,
        //     name: "Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A  (16 Inch, Space Black, 2.16 kg)",
        //     image: './assets/images/Laptop4.png',
        //     price: "₹1,49,990",
        //     originalPrice: "₹1,89,990",
        //     discount: "21% off",
        //     rating: 4,
        //     liked: false
        // },

    ];
    return (
        <Grid container
            direction="column"
            sx={{
                height: 'auto',
                border: '4px solid grey',
                borderRadius: '8px',
                paddingBottom:'5px'
            }}
        >
            <Navbar />
            <Grid container sx={{ justifyContent: 'center', mt: '10px' }}>
                <Grid container sx={{ width: '90%', }} >
                    <Grid item xs={3.5} sx={{ borderRadius: '8px', height: 'auto', backgroundColor: lighten(extractedColor, 0.5), padding: '8px' }}>
                        <Typography sx={{ fontSize: '10px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Filter</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '2px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Category</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ backgroundColor: '#FFFFFF', padding: '5px 5px 0px 5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Price</Typography>
                                <Icon icon="solar:alt-arrow-up-outline" width="13px" height="13px" style={{ color: '#000000' }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                                <Button variant="outlined" sx={{ border: `1px solid ${extractedColor}`, fontSize: '9px', padding: '0px', textTransform: 'none', color: '#000000' }}>Min.Price</Button>
                                <Button variant="outlined" sx={{ border: `1px solid ${extractedColor}`, fontSize: '9px', padding: '0px', textTransform: 'none', color: '#000000' }}>Max.Price</Button>
                            </Box>
                            <Slider sx={{
                                color: extractedColor, '& .MuiSlider-thumb': { width: 10, height: 10, },
                                '& .MuiSlider-track': {
                                    height: 4,
                                },
                                '& .MuiSlider-rail': {
                                    height: 4,
                                },
                            }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Brand</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Type</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Processor</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Generation</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Screen Size</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>Operating System</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>RAM Capacity</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '4px', alignItems: 'center', mt: '3px' }}>
                            <Typography sx={{ fontSize: '9px',  fontFamily:'Inter', color: '#000000', lineHeight: '12px', fontWeight: '500', }}>SSD Capacity</Typography>
                            <Icon icon="solar:alt-arrow-down-linear" width="13px" height="13px" style={{ color: '#000000' }} />

                        </Box>


                    </Grid>
                    <Grid item xs={8.4} sx={{ ml: '4px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid black', padding: '0px 5px' }}>
                            <Typography sx={{ fontSize: '11px',  fontFamily:'Inter', color: '#000000', fontWeight: '500', }}>Viewing results for <sapn style={{ color: extractedColor }}>"Laptop"</sapn></Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="mynaui:sort-solid" width="13px" height="13px" style={{ color: '#000000' }} />
                                <Typography sx={{ fontSize: '11px',  fontFamily:'Inter', color: '#000000', lineHeight: '0px', fontWeight: '500', }}>Sort</Typography>
                            </Box>


                        </Box>
                        <Grid container sx={{ mt: '5px' }}>
                            {products.map((product, index) => (
                                <Grid item xs={12} key={index} sx={{}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'relative',
                                            padding: '5px 0px 10px 5px',
                                        }}
                                    >
                                        {/* Product Image */}
                                        <Box
                                            // component="img"
                                            // src={product.image}
                                            // alt={product.name}
                                            // sx={{ width: '90px', height: '80px',  }}
                                            component="img"
                                            height="85px"
                                            src={product.image}
                                            alt={product.name}
                                            sx={{ width: '140px', padding: '0px' }}
                                        />
                                        <Box sx={{ position: 'absolute', top: '0px', right: '0px' }}>
                                            <IconButton>
                                                <Icon
                                                    icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                                    style={{ color: extractedColor, fontSize: '15px' }}
                                                />
                                            </IconButton>
                                        </Box>

                                        {/* Product Details */}
                                        <Box sx={{ marginLeft: '5px', flexGrow: 1, maxWidth: '210px' }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: '500',
                                                    fontSize: '9px',
                                                    color: '#000',
                                                     fontFamily:'Inter',
                                                    mb: '1px',
                                                }}
                                            >
                                                {product.name}
                                            </Typography>

                                            {/* Rating */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: '1px' }}>
                                                {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                                                    <Icon
                                                        key={i}
                                                        icon="mdi:star"
                                                        style={{ color: extractedColor, fontSize: '13px', marginRight: '2px' }}
                                                    />
                                                ))}
                                            </Box>

                                            {/* Price Section */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: '2px' }}>
                                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '10px',  fontFamily:'Inter', }}>
                                                    {product.price}
                                                </Typography>

                                                <Typography sx={{ textDecoration: 'line-through', mr: 1, color: '#9e9e9e', fontSize: '9px', ml: '5px',  fontFamily:'Inter', }}>
                                                    {product.originalPrice}
                                                </Typography>
                                                <Typography sx={{ color: extractedColor, fontSize: '9px',  fontFamily:'Inter', }}>
                                                    {product.discount}
                                                </Typography>

                                            </Box>
                                            <Button
                                                variant="contained"

                                                sx={{

                                                    backgroundColor: lighten(extractedColor, 0.2),
                                                    '&:hover': { backgroundColor: `${extractedColor}` },
                                                    textTransform: 'none',
                                                    color: '#4B5563',
                                                    fontWeight: '600',
                                                    fontSize: '8px',
                                                     fontFamily:'Inter',
                                                    borderRadius: '4px',
                                                    padding: '4px 14px'
                                                }}
                                            >
                                                Add to cart
                                            </Button>

                                        </Box>
                                    </Box>
                                    {index < products.length - 1 && (
                                        <Divider
                                            sx={{
                                                borderColor: 'grey.400',
                                                borderWidth: '1px',
                                                marginX: '7px',
                                                opacity: 0.6,
                                            }}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </Grid>

    )
}

export default ProductListScreen
