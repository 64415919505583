import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import Navbar from './Navbar';
import ReusableButton from '../../../components/Button';
import Footer from './Footer';


const HomeScreen = (data) => {
  const storedAppData = data.data;
  console.log("ddd", storedAppData);
  const colorGradient = storedAppData.theme_color;
  const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
  const extractedColor = colorMatch ? colorMatch[0] : null;
  const products = [
    {
      name: 'Apple MacBook Pro',
      price: '₹80,915',
      originalPrice: '₹99,000',
      discount: '15%off',
      image: './assets/images/Laptop1.png',
      rating: 3,
      liked: true,
    },
    {
      name: 'Lenovo Legion 9',
      price: '₹49,990',
      originalPrice: '₹50,000',
      discount: '28%off',
      image:'./assets/images/Laptop2.png',
      rating: 4,
      liked: false,
    },
    {
      name: 'Apple 2020 MacBook',
      price: '₹60,891',
      originalPrice: '₹70,000',
      discount: '10%off',
      image: './assets/images/Laptop3.png',
      rating: 4,
      liked: false,
    },
    {
      name: 'MSI Titan 18HX',
      price: '₹44,000',
      originalPrice: '₹55,000',
      discount: '18%off',
      image: './assets/images/Laptop4.png',
      rating: 3,
      liked: true,
    },
  ];
  const products2 = [
    {
      name1: 'CORNELIANI',
      name: 'Black Comfort Blazer',
      price: '₹3,39,915',
      originalPrice: '₹3,99,000',
      discount: '15% off',
      image: './assets/images/Shirt1.png',
      rating: 3,
      liked: true,
    },
    {
      name1: 'GANT',
      name: 'Black Polo T-shirt',
      price: '₹4,49,990',
      originalPrice: '₹4,49,990',
      discount: '28% off',
      image: './assets/images/Shirt2.png',
      rating: 4,
      liked: false,
    },
    {
      name1: 'GANT',
      name: 'Black Polo T-shirt',
      price: '₹4,49,990',
      originalPrice: '₹4,49,990',
      discount: '28% off',
      image: './assets/images/Shirt3.png',
      rating: 4,
      liked: false,
    },


  ];
  const products3 = [
    {
      name: 'Fashion & Apparel',
      image: './assets/images/Shirt1.png',
    },
    {
      name: 'Electronics',
      image: './assets/images/Laptop1.png',
    },
    {
      name: 'Home & Furniture',
      image: './assets/images/Mixed1.png',
    },
    {
      name: 'Home & Furniture',
      image: './assets/images/Mixed2.png',
    },
  ];
  return (
    <Grid container
      direction="column"
      sx={{
        height: 'auto',
        border: '4px solid grey',
        borderRadius: '8px',
      }}
    >
      <Navbar />
      <Grid container item sx={{ justifyContent: 'center' }}>
        <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
          <Box
            sx={{
              display: 'flex',
              backgroundImage: "url('./assets/images/chair.jpeg')", 
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '300px',
              padding: '0 30px',
              position: 'relative',
              height: '200px',
              mt: '10px',
              borderRadius: '8px'
            }}
          >

            <Box sx={{ mt: '115px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '500',  fontFamily:'Inter', lineHeight: '21px', maxWidth: '150px' }}>
                Stylish Furniture for Every Room
              </Typography>
              <Typography sx={{ mt: '3px', maxWidth: '200px', fontSize: '8px', fontWeight: '500',  fontFamily:'Inter', lineHeight: '13px',maxWidth:'170px' }}>
                Comfort meets design. Discover the perfect pieces to complete your home.
              </Typography>
            </Box>


            <Box
              component="button"
              sx={{
                backgroundColor: lighten(extractedColor, 0.2),
                color: '#000',
                boxShadow: 'none',
                borderRadius: '4px',
                width: '115px',
                height: '29px',
                fontSize: '12px',
                fontWeight: '500',
                 fontFamily:'Inter',
                textTransform: 'none',
                mt: '150px',
                ml: '180px',
                border: 'none',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: lighten(extractedColor, 0.2),
                  color: '#000000'
                },
              }}
            >
              Shop Now!
            </Box>



            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '8px',
                transform: 'translateY(-50%)',
                backgroundColor: '#e6e0f0',
                height: '40px',
                width: '20px',
                borderRadius: '4px',
                cursor: 'pointer',
                textAlign: 'center',
                alignContent: 'center',

              }}
            >
              <Icon icon="ri:play-reverse-fill" width="12px" height="12px" style={{ color: '#000000' }} />
              {/* <Icon icon="ri:play-reverse-mini-fill" width="12px" height="12px"  style={{color: '#000000'}} /> */}

            </Box>

            <Box
              sx={{
                position: 'absolute',
                top: '49%',
                right: '8px',
                transform: 'translateY(-50%)',
                backgroundColor: '#e6e0f0',
                height: '40px',
                width: '20px',
                borderRadius: '4px',
                cursor: 'pointer',
                textAlign: 'center',
                alignContent: 'center'
              }}
            >

              <Icon icon="line-md:play-filled" width="12px" height="12px" style={{ color: '#000000' }} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 0px 5px' }}>
            <Typography sx={{ fontSize: '13px', fontWeight: '500',  fontFamily:'Inter', lineHeight: '14px' }}>Great Deals On Laptops</Typography>
            <Typography sx={{ fontSize: '13px', fontWeight: '500',  fontFamily:'Inter', color: `${extractedColor}`, lineHeight: '14px' }}>View all</Typography>
          </Box>
          <Grid container spacing={'5px'} sx={{ mt: '6px' }}>
            {products.map((product, index) => (
              <Grid item xs={3} key={index}>
                <Card sx={{ border: ` 1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px', position: 'relative' }}>
                  <Box sx={{ position: 'absolute', top: '4px', right: '1px' }}>
                    <IconButton>
                      <Icon
                        icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                        style={{ color: `${extractedColor}`, fontSize: '12px' }}
                      />
                    </IconButton>
                  </Box>
                  <CardMedia
                    component="img"
                    height="72px"
                    image={product.image}
                    alt={product.name}
                    sx={{ width:'117px', mt: '5px', padding: '10px' }}
                  />
                  <Box sx={{ padding: '0px 10px' }}>

                    <Typography sx={{ fontWeight: '500', fontSize: '9px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                      {product.name}
                    </Typography>
                    <Box sx={{ mt: '1px', display: 'flex',mb:'0px' }}>

                      <Typography sx={{ color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif' }}>
                        {product.price}
                      </Typography>

                      <Typography sx={{ textDecoration: 'line-through', color: '#9e9e9e', fontSize: '9px',  fontFamily:'Inter', ml: '3px' }}>
                        {product.originalPrice}
                      </Typography>
                      <Typography sx={{ color: `${extractedColor}`, fontSize: '9px',  fontFamily:'Inter', ml: '3px' }}>
                        {product.discount}
                      </Typography>
                      

                    </Box>
                    {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                      <Icon key={i} icon="mdi:star" style={{ color: `${extractedColor}`, fontSize: '12px',padding:'0px' }} />
                    ))}

                   
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: 'center', backgroundColor: lighten(extractedColor, 0.5), mt: '5px', paddingBottom: '10px' }}>
        <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
          <Box sx={{}}>
            <Typography sx={{ fontSize: '13px', fontWeight: '500', lineHeight: '14px',  fontFamily:'Inter', color: '#000000', mt: '10px' }}>You might be interested in</Typography>
            <Box sx={{ display: 'flex', mt: '5px', gap: '8px' }}>
              <Typography sx={{ fontSize: '9px', fontWeight: '500', lineHeight: '14px',  fontFamily:'Inter', color: '#000000', textDecoration: 'underline', textUnderlineOffset: '5px', }}>Fashion & appereal</Typography>
              <Typography sx={{ fontSize: '9px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Electronics & Gadgets</Typography>
              <Typography sx={{ fontSize: '9px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Home & Kitchen</Typography>
              <Typography sx={{ fontSize: '9px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Health & Beauty</Typography>
              <Typography sx={{ fontSize: '9px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Sports & Outdoor</Typography>
              <Typography sx={{ fontSize: '9px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Books & Media</Typography>
            </Box>
          </Box>
          <Grid container spacing={'5px'} sx={{ mt: '6px' }}>
            {products2.map((product, index) => (
              <Grid item xs={4} key={index} sx={{}}> {/* Adjust xs value to control number of items per row */}
                <Box
                  sx={{

                    borderRadius: '8px',
                    position: 'relative',
                    border: `1px solid ${lighten(extractedColor, 0.5)}`,  // Light border
                    display: 'flex',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#FFFFFF',
                    height: '150px',
                    alignItems: 'center'
                  }}
                >
                  {/* Heart icon at the top-right corner */}
                  <Box sx={{ position: 'absolute', top: '0px', right: '0px' }}>
                    <IconButton>
                      <Icon
                        icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                        style={{ color: `${extractedColor}`, fontSize: '13px' }}
                      />
                    </IconButton>
                  </Box>

                  {/* Product image */}
                  <Box
                    component="img"
                    src={product.image}
                    alt={product.name}
                    sx={{
                      height: '100px',
                      width: '90px',
                      borderRadius: '15px',
                    }}
                  />

                  {/* Product details */}
                  <Box sx={{ mt: '0px' }}> {/* Space between image and details */}
                    <Typography sx={{ fontWeight: '500', fontSize: '9px', color: '#6B7280', fontFamily: '"Inter", sans-serif' }}>
                      {product.name1}
                    </Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '8px',  fontFamily:'Inter', color: '#000000', mt: '2px' }}>
                      {product.name}
                    </Typography>

                    {/* Product price and discount */}
                    <Box sx={{ display: 'flex', }}>
                      <Typography sx={{ color: '#000',  fontFamily:'Inter', fontSize: '9px', mt: '2px' }}>
                        {product.price}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mt: '2px' }}>
                      <Typography sx={{ textDecoration: 'line-through', color: '#9e9e9e', fontSize: '9px', }}>
                        {product.originalPrice}
                      </Typography>
                      <Typography sx={{ color: `${extractedColor}`, fontSize: '9px', ml: '3px' }}>
                        {product.discount}
                      </Typography>
                    </Box>

                    {/* Product rating */}
                    <Box sx={{ display: 'flex', mt: '2px' }}>
                      {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                        <Icon key={i} icon="mdi:star" style={{ color: `${extractedColor}`, fontSize: '10px' }} />
                      ))}
                    </Box>

                    {/* "Shop Now" button */}
                    <Box sx={{ mt: '4px' }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: `${extractedColor}`,
                          color: '#000000',
                          fontSize: '8px',
                          fontWeight: '500',
                          textTransform: 'none',
                          padding: '4px 10px',
                          borderRadius: '4px',
                           fontFamily:'Inter',
                          '&:hover': { backgroundColor: `${extractedColor}`,color:'#000000' }
                        }}
                      >
                        Shop Now!
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: 'center' }}>
        <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px' }}>
            <Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Shop by category</Typography>
            <Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#6B7280',  fontFamily:'Inter', color: `${extractedColor}` }}>View all</Typography>
          </Box>
          <Grid container spacing={1} sx={{ mt: '6px' }}>
            {products3.map((product, index) => (
              <Grid item xs={3} key={index}>
                <Card sx={{ border: ` 1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px', padding: '12px', position: 'relative' }}>

                  <CardMedia
                    component="img"
                    height="90px"
                    image={product.image}
                    alt={product.name}
                    sx={{ objectFit: 'contain', mt: '5px' }}
                  />

                  <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: 'Inter' }}>
                    {product.name}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ReusableButton
                      sx={{
                        '&:hover': { backgroundColor: `${extractedColor}` },
                        textTransform: 'none',
                        backgroundColor: `${extractedColor}`,
                        padding: '4px 10px',
                        fontWeight: '500',
                        textTransform: 'none',
                        padding: '4px 10px',
                        borderRadius: '4px',
                         fontFamily:'Inter',
                        fontSize:'9px'

                      }}
                    >Shop now!</ReusableButton>
                  </Box>

                </Card>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Grid>
      {/*footer*/}

      <Footer />


    </Grid>
  )
}

export default HomeScreen
