import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, Divider, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const ProductsList = (data) => {
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;

    // Extract color from the gradient string
    const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
    const extractedColor = colorMatch ? colorMatch[0] : '#000'; // fallback color
    const products = [
        {
            id: 1,
            name: "Apple MacBook Pro M3 Max - (48 GB/1 TB SSD)",
            image: './assets/images/Laptop1.png',
            price: "₹3,39,915",
            originalPrice: "₹3,99,990",
            discount: "15% off",
            rating: 4.5,
            liked: true 
        },
        {
            id: 2,
            name: "MSI Titan 18HX Intel Core i9 14th Gen 14900HX",
            image: './assets/images/Laptop2.png',
            price: "₹4,09,990",
            originalPrice: "₹4,59,990",
            discount: "18% off",
            rating: 5,
            liked: false 
        },
        {
            id: 3,
            name: "Dell XPS 13 (11th Gen Core i7/16GB RAM/512GB SSD)",
            image: './assets/images/Laptop3.png',
            price: "₹1,49,990",
            originalPrice: "₹1,89,990",
            discount: "21% off",
            rating: 4,
            liked: false
        },
        {
            id: 4,
            name: "Asus ROG Strix G15 (Ryzen 9 5900HX/16GB RAM/1TB SSD)",
            image: './assets/images/Laptop4.png',
            price: "₹1,69,990",
            originalPrice: "₹2,19,990",
            discount: "23% off",
            rating: 4.2,
            liked: true
        },
        {
            id: 5,
            name: "Apple MacBook Pro M3 Max - (48 GB/1 TB SSD)",
            image: './assets/images/Laptop1.png',
            price: "₹3,39,915",
            originalPrice: "₹3,99,990",
            discount: "15% off",
            rating: 4.5
        },
    ];

    return (
        <Grid container
            sx={{
                height: '710px',
                border: '10px solid grey',
                borderRadius: '20px',
                width: '300px',
                position: 'relative'
            }}
        >
            <TopNotch />
            <Grid container item sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: '22px',
                padding: '10px 6px',
                boxShadow: `0px 4px 4px -2px ${lighten(extractedColor,0.7)}`,
                height: '55px',
                alignItems: 'center'

            }}>
                <Icon icon="line-md:arrow-left" width="24px" height="24px" style={{ color: '#000000' }} />

                {/* Search bar */}
                <Box
                    sx={{
                        height: '30px',
                        border: `0.6px solid ${lighten(extractedColor, 0.8)}`,
                        padding: '0px 5px',
                        width: '200px',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: lighten(extractedColor, 0.9),

                    }}
                >
                    <Icon icon="mdi:magnify" width="20px" height="20px" style={{ color: 'grey', marginRight: '5px' }} />
                    <Typography
                        sx={{
                            color: 'grey',
                            fontSize: '12px',
                            fontWeight: '400',
                            flex: 1,
                        }}
                    >
                        Laptop
                    </Typography>
                </Box>
                <Icon icon="mdi:cart" width="24px" height="24px" style={{ color: '#000000', }} />
            </Grid>

            <Grid container sx={{ mt: '0px' }}>
                {products.map((product, index) => (
                    <Grid item xs={12} key={index} sx={{}}>
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'relative',
                                padding: '16px',
                                backgroundColor: '#fff',


                            }}
                        >
                            {/* Product Image */}
                            <Box
                                component="img"
                                src={product.image}
                                alt={product.name}
                                sx={{ width: '95px', height: '56px', mt:'5px' }}
                            />
                            <Box sx={{ position: 'absolute', top: '8px', right: '0px' }}>
                                <IconButton>
                                    <Icon
                                        icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                        style={{ color: extractedColor, fontSize: '20px' }}
                                    />
                                </IconButton>
                            </Box>

                            {/* Product Details */}
                            <Box sx={{marginLeft:'10px',  flexGrow: 1 ,maxWidth:'130px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '10px',
                                        color: '#000000',
                                         fontFamily:'Inter',
                                       
                                    }}
                                >
                                    {product.name}
                                </Typography>

                                {/* Rating */}
                                <Box sx={{ display: 'flex', alignItems: 'center',mb:'2px',mt:'1px'  }}>
                                    {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                                        <Icon
                                            key={i}
                                            icon="mdi:star"
                                            style={{ color: extractedColor, fontSize: '10px', marginRight: '2px' }}
                                        />
                                    ))}
                                </Box>

                                {/* Price Section */}
                                <Box>
                                    <Typography  sx={{ fontWeight: '500', color: '#000', fontSize: '10px', fontFamily: '"Inter", sans-serif' }}>
                                        {product.price}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center',  }}>
                                        <Typography sx={{ textDecoration: 'line-through', mr: 1, color: '#9e9e9e', fontSize: '10px', fontFamily: '"Inter", sans-serif' }}>
                                            {product.originalPrice}
                                        </Typography>
                                        <Typography sx={{ color: extractedColor, fontSize: '10px', fontFamily: '"Inter", sans-serif' }}>
                                            {product.discount}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {index < products.length - 1 && (
                        <Divider
                            sx={{
                                borderColor: 'grey.400',
                                borderWidth: '1px',
                                marginX: '7px',
                                opacity: 0.6,
                            }}
                        />
                        )}
                    </Grid>
                ))}
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center', 
                    padding: '7px 10px 10px 10px',
                    boxShadow: `0px -4px 8px -2px ${lighten(extractedColor, 0.7)}`,
                    width: '100%',
                    mb: '10px',
                    mt: '5px'
                }}
            >
                <Box sx={{ display: 'flex', borderRadius: '8px', border: `1px solid ${lighten(extractedColor, 0.5)}`, padding: '4px 20px', gap: '4px', alignItems: 'center' }}>
                    <Icon icon="iconoir:filter" width="12px" height="12px" style={{ color: '#000000' }} />
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000', fontFamily: 'Inter' }}>Filter</Typography>

                </Box>
                <Box
                    sx={{
                        width: '1px',
                        height: '24px',
                        backgroundColor: `${lighten(extractedColor, 0.5)}`,
                        margin: '0 15px',
                    }}
                />
                <Box sx={{ display: 'flex', borderRadius: '8px', border: `1px solid ${lighten(extractedColor, 0.5)}`, padding: '4px 20px', gap: '4px', alignItems: 'center' }}>

                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000', fontFamily: 'Inter' }}>Sort</Typography>
                    <Icon icon="solar:sort-linear" width="12px" height="12px" style={{ color: '#000000' }} />

                </Box>
            </Box>

            <BottomNotch />
        </Grid>

    )
}

export default ProductsList
