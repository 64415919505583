import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const HomePage = (data) => {
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;



    const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
    const extractedColor = colorMatch ? colorMatch[0] : '#000';

    const products = [
        {
            name: 'Apple MacBook Pro',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image: './assets/images/Laptop1.png',
            rating: 3,
            liked: true,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: './assets/images/Laptop2.png',
            rating: 4,
            liked: false,
        },
        {
            name: 'Apple 2020 MacBook',
            price: '₹90,891',
            originalPrice: '₹1,00,000',
            discount: '10% off',
            image: './assets/images/Laptop3.png',
            rating: 4,
            liked: false,
        },
        {
            name: 'MSI Titan 18HX',
            price: '₹4,09,990',
            originalPrice: '₹4,99,990',
            discount: '18% off',
            image: './assets/images/Laptop4.png',
            rating: 3,
            liked: true,
        },
    ];
    const products2 = [
        {
            name1: 'CORNELIANI',
            name: 'Black Comfort Blazer',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image: './assets/images/Shirt1.png',
            rating: 3,
            liked: true,
        },
        {
            name1: 'GANT',
            name: 'Black Polo T-shirt',
            price: '₹4,49,990',
            originalPrice: '₹4,49,990',
            discount: '28% off',
            image: './assets/images/Shirt2.png',
            rating: 4,
            liked: false,
        },

    ];
    const products3 = [
        {
            name: 'Fashion & Apparel',
            image: './assets/images/Shirt1.png',
        },
        {
            name: 'Electronics',
            image: './assets/images/Laptop1.png',
        },
        {
            name: 'Home & Furniture',
            image: './assets/images/Mixed1.png',
        },
        {
            name: 'Home & Furniture',
            image: './assets/images/Mixed2.png',
        },
    ];

    return (
        <Grid container
            sx={{
                height: '1480px',
                border: '10px solid grey',
                borderRadius: '20px',
                width: '300px',
                position: 'relative'
             }}
        >
            <TopNotch />

            <Grid container item sx={{
                display: 'flex', justifyContent: 'space-between', padding: '10px 6px',
                boxShadow: `0px 4px 4px -2px ${lighten(extractedColor,0.7)}`, height: '55px', mb: '0px', mt: '20px'
            }}>
                <Icon icon="gg:menu" width="24px" height="24px" style={{ color: "#000000", marginTop: '5px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={storedAppData?.logo?.selectedImage || "../assets/images/defaultLogo.png"}
                        alt="Logo"
                        style={{ width: '24px', height: '24px' }}
                    />
                    <Typography sx={{  fontFamily:storedAppData?.fontFamily ? storedAppData.fontFamily : 'Inter', fontSize: '15px', ml: '5px', fontWeight: '500', lineHeight: '16px' }}>
                        {storedAppData.application_name}
                    </Typography>
                </Box>
                <Icon icon="mdi:cart" width="24px" height="24px" style={{ color: '#000000', marginTop: '5px' }} />
            </Grid>
            <Box sx={{ padding: '0px 5px', mt: '0px', }}>

                {/* Search bar */}
                <Box
                    sx={{
                        height: '30px',
                        border: `0.6px solid ${lighten(extractedColor, 0.8)}`, 
                        padding: '0px 5px', 
                        width: '100%', 
                        borderRadius: '0.5rem', 
                        display: 'flex', 
                        alignItems: 'center', 
                        backgroundColor: lighten(extractedColor, 0.9), 
                        mb:'10px'
                    }}
                >
                    <Icon icon="mdi:magnify" width="20px" height="20px" style={{ color: 'grey', marginRight: '5px' }} />
                    <Typography
                        sx={{
                            color: 'grey',
                            fontSize: '12px', 
                            fontWeight: '400',
                            flex: 1, 
                        }}
                    >
                        Search for Smartphone
                    </Typography>
                </Box>

                {/* Promotional Banner */}
                <Box
                    sx={{
                        borderRadius: '15px',
                        backgroundImage: "url('./assets/images/chair.jpeg')", 
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',
                        padding: '4px 8px',
                        height: '150px'

                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '83px', }}>
                        <Box>
                            <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#333',  fontFamily:'Inter', }}>
                                Stylish<br></br> Furniture

                            </Typography>
                            <Typography sx={{ fontSize: '8px',  fontFamily:'Inter', }}>Comfort meets design.</Typography>
                        </Box>
                        {/* <Button  sx={{ backgroundColor: extractedColor,height:'14px',width:'52px',fontSize:'0px',fontWeight:'500', fontFamily:'Inter',color:'#000000' }}>
                            Shop Now
                        </Button> */}
                        <Box>

                            <ReusableButton
                                sx={{
                                    '&:hover': { backgroundColor: `${lighten(extractedColor,0.5)}` },
                                    textTransform: 'none',
                                    backgroundColor: `${lighten(extractedColor,0.5)}`,
                                    height: '25px',
                                    mt: '20px',
                                    width:'72px',
                                    fontSize:'12px',
                                    alignItems:'center',
                                     fontFamily:'Inter',
                                }}
                            >Shop now</ReusableButton>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', mt: '5px' }}>

                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: extractedColor, }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#dbd7d2', }} />
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 0px 5px' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500',  fontFamily:'Inter', lineHeight: '14px' }}>Great Deals On Laptops</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '500',  fontFamily:'Inter', color: `${extractedColor}`, lineHeight: '14px' }}>View all</Typography>
                   
                </Box>


                {/* Great Deals on Laptops Section */}
                <Grid container spacing={1} sx={{ mt: '6px' }}>
                    {products.map((product, index) => (
                        <Grid item xs={6} key={index}>
                            <Card sx={{ border: ` 1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px', padding: '12px',paddingBottom:'0px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: '2px', right: '0px' }}>
                                    <IconButton>
                                        <Icon
                                            icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                            style={{ color: `${extractedColor}`, fontSize: '20px' }}
                                        />
                                    </IconButton>
                                </Box>
                                <CardMedia
                                    component="img"
                                    height="90px"
                                    image={product.image}
                                    alt={product.name}
                                    sx={{ objectFit: 'contain', mt: '5px' }}
                                />

                                <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                                    {product.name}
                                </Typography>
                                <Box sx={{ mt: '3px' }}>

                                    <Typography  sx={{ color: '#000',fontSize:'12px',fontWeight:'500',fontFamily:'"Inter", sans-serif'}}>
                                        {product.price}
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ textDecoration: 'line-through', mr: 1, color: '#9e9e9e', fontSize: '12px' }}>
                                            {product.originalPrice}
                                        </Typography>
                                        <Typography sx={{ color: `${extractedColor}`, fontSize: '12px' }}>
                                            {product.discount}
                                        </Typography>
                                    </Box>
                                </Box>
                                {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                                    <Icon key={i} icon="mdi:star" style={{ color: `${extractedColor}`, fontSize: '12px' }} />
                                ))}

                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{}}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '14px',  fontFamily:'Inter', color: '#000000', mt: '13px' }}>You might be interested in</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                        <Typography sx={{ fontSize: '10px', fontWeight: '500', lineHeight: '14px',  fontFamily:'Inter', color: '#000000', textDecoration: 'underline', textUnderlineOffset: '5px', }}>Fashion & appereal</Typography>
                        <Typography sx={{ fontSize: '10px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Electronics & Gadgets</Typography>
                        <Typography sx={{ fontSize: '10px', fontWeight: '400', lineHeight: '14px',  fontFamily:'Inter', color: '#6B7280' }}>Home &</Typography>

                    </Box>
                </Box>
                <Grid container spacing={1} sx={{ mt: '6px' }}>
                    {products2.map((product, index) => (
                        <Grid item xs={6} key={index}>
                            <Card sx={{ borderRadius: '8px', padding: '12px',paddingBottom:'0px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: '4px', right: '1px' }}>
                                    <IconButton>
                                        <Icon
                                            icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                            style={{ color: `${extractedColor}`, fontSize: '20px' }}
                                        />
                                    </IconButton>
                                </Box>
                                <CardMedia
                                    component="img"
                                    height="90px"
                                    image={product.image}
                                    alt={product.name}
                                    sx={{ objectFit: 'contain', }}
                                />
                                <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#6B7280', mt: '10px', fontFamily: '"Inter", sans-serif' }}>
                                    {product.name1}
                                </Typography>

                                <Typography sx={{ fontWeight: '400', fontSize: '10px',  fontFamily:'Inter', color: '#000000' }}>
                                    {product.name}
                                </Typography>
                                <Box sx={{ mt: '3px' }}>

                                    <Typography  sx={{ color: '#000',fontSize:'12px',fontWeight:'500',fontFamily:'"Inter", sans-serif'}}>
                                        {product.price}
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ textDecoration: 'line-through', mr: 1, color: '#9e9e9e', fontSize: '12px', }}>
                                            {product.originalPrice}
                                        </Typography>
                                        <Typography sx={{ color: `${extractedColor}`, fontSize: '12px', }}>
                                            {product.discount}
                                        </Typography>
                                    </Box>
                                </Box>
                                {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                                    <Icon key={i} icon="mdi:star" style={{ color: `${extractedColor}`, fontSize: '13px' }} />
                                ))}

                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px',padding:'0px 5px' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Shop by category</Typography>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#6B7280',  fontFamily:'Inter', color: `${extractedColor}` }}>View all</Typography>
                </Box>
                <Grid container spacing={1} sx={{ mt: '6px' }}>
                    {products3.map((product, index) => (
                        <Grid item xs={6} key={index}>
                            <Card sx={{ border: ` 1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px', padding: '12px', position: 'relative' }}>

                                <CardMedia
                                    component="img"
                                    height="90px"
                                    image={product.image}
                                    alt={product.name}
                                    sx={{ objectFit: 'contain', mt: '5px' }}
                                />

                                <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: 'Inter' }}>
                                    {product.name}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReusableButton
                                        sx={{
                                            '&:hover': { backgroundColor: `${lighten(extractedColor,0.5)}` },
                                            textTransform: 'none',
                                            backgroundColor: `${lighten(extractedColor,0.5)}`,
                                            height: '25px',
                                              fontFamily:'Inter',
                                             fontSize:'10px'


                                        }}
                                    >Shop now</ReusableButton>
                                </Box>

                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center', // Center content vertically
                    padding: '7px 10px 10px 10px',
                    boxShadow: `0px -4px 8px -2px ${lighten(extractedColor,0.7)}`,
                    width: '100%',
                    mb: '5px'
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Icon icon="ic:round-home" width="20px" height="20px" style={{ color: extractedColor }} />
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '11px',
                            lineHeight: '14px',
                            color: '#000000',
                             fontFamily:'Inter',

                        }}
                    >
                        Home
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Icon icon="ic:baseline-category" width="20px" height="20px" style={{ color: 'black' }} />
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '11px',
                            lineHeight: '14px',
                            color: '#000000',
                             fontFamily:'Inter',

                        }}
                    >
                        Categories
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Icon icon="ic:baseline-favorite-border" width="20px" height="20px" style={{ color: 'black' }} />
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '11px',
                            lineHeight: '14px',
                            color: '#000000',
                             fontFamily:'Inter',

                        }}
                    >
                        Wishlist
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Icon icon="ic:round-account-circle" width="20px" height="20px" style={{ color: 'black' }} />
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '11px',
                            lineHeight: '14px',
                            color: '#000000',
                             fontFamily:'Inter',

                        }}
                    >
                        Profile
                    </Typography>
                </Box>
            </Box>
            <BottomNotch />

        </Grid>
    );
}

export default HomePage;
