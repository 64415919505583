import { Box, Grid,Typography } from '@mui/material'
import React from 'react'
import ReusableFormTextField from '../../../components/TextField';
import { Icon } from "@iconify/react";
import { lighten } from '@mui/system';

const Navbar = () => {
    const storedData = JSON.parse(sessionStorage.getItem("appData"));
    const storedAppData = storedData;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
  
  
    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found
  return (
    <>
    <Grid container sx={{height:'40px',justifyContent:'space-around',alignItems:'center',   boxShadow: `0px 4px 4px -2px ${lighten(extractedColor,0.6)}`}}>
        <Box  sx={{display:'flex',alignItems:'center'}}>
        <img
          src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
            ? storedAppData.logo.selectedImage
            : "../assets/images/defaultLogo.png"}

          style={{ width: '24px', height: '24px' }}
        />

            <Typography sx={{fontSize:'15px',fontWeight:'500',fontFamily:storedAppData?.fontFamily ? storedAppData.fontFamily : 'Inter',lineHeight:'12px',ml:'4px'}}>{storedAppData.application_name}</Typography>
        </Box>
        <Box sx={{display:'flex',gap:'5px',alignItems:'center'}} >
      
                      {/* Search bar */}
                <Box
                    sx={{
                        height: '20px',
                        border: `0.6px solid ${lighten(extractedColor, 0.8)}`, 
                        padding: '0px 5px', 
                        width: '100%', 
                        borderRadius: '4px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        backgroundColor: lighten(extractedColor, 0.9), 
                      
                    }}
                >
                    <Icon icon="mdi:magnify" width="13px" height="13px" style={{ color: 'grey', marginRight: '1px' }} />
                    <Typography
                        sx={{
                            color: 'grey',
                            fontSize: '8px', 
                            fontWeight: '400',
                            flex: 1, 
                        }}
                    >
                        Search for Smartphone
                    </Typography>
                </Box>
                    <Box  sx={{display:'flex',alignItems:'center',ml:'20px'}}>
                     <Icon icon="ic:baseline-favorite-border" width="13px" height="13px" style={{ color: 'black' }} />
            <Typography sx={{fontSize:'9px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',ml:'1px'}}>Wishlist</Typography>
            </Box>
            <Box  sx={{display:'flex',alignItems:'center',ml:'5px'}}>
            <Icon icon="mdi:cart" width="13px" height="13px" style={{ color: '#000000' }} />
            <Typography sx={{fontSize:'9px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',ml:'1px',}} >Cart</Typography>
            </Box>
            <Box  sx={{display:'flex',alignItems:'center',ml:'5px'}}>
            <Icon icon="ic:round-account-circle" width="13px" height="13px" style={{ color: 'black' }} />
            <Typography sx={{fontSize:'9px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',ml:'1px'}}>Profile</Typography>
            </Box>
        </Box>
        

    </Grid>
    <Box sx={{display:'flex',justifyContent:'space-around',height:'25px',mt:'10px',alignItems:'center', backgroundColor: lighten(extractedColor, 0.6) }}>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Fashion & Apparel</Typography>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Electronics & Gadgets</Typography>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Home & Kitchen</Typography>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Health & Beauty</Typography>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Toys & Games</Typography>
        <Typography sx={{fontSize:'8px',fontWeight:'500',fontFamily: '"Inter", sans-serif',lineHeight:'12px',}}>Books & Media</Typography>

    </Box>
    </>
  )
}

export default Navbar
